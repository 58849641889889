
import { defineComponent, PropType, ref } from "vue";
import { Product } from "@/graphql/types";
import { usePosProduct } from "@/components/pos/pos-product";
import PosChooseStock from "@/components/pos/PosChooseStock.vue";

export default defineComponent({
  name: "CodeFinder",
  components: { PosChooseStock },
  emits: ["addToTicketRow"],
  props: {
    products: {
      type: Array as PropType<Product[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      stocks,
      stockOverlay,
      saleRow,
      onCodeBarSearch,
      onQuantityValidate,
    } = usePosProduct(emit);

    const search = ref("");

    function handleCode(event: any) {
      const el = document.getElementById("code-bar-input");
      if (!search.value) {
        el.focus();
        return;
      }
      onCodeBarSearch([event, search.value], props.products as Product[]);
      setTimeout(() => {
        // el.blur();
        el.focus();
        search.value = "";
      }, 2);
    }

    function handleKeyup(event: KeyboardEvent) {
      switch (event.key.toLowerCase()) {
        case "enter":
          break;
        case "tab":
          event.preventDefault();
          document.getElementById("submit-code-found").click();
          break;
      }
    }

    return {
      search,
      stocks,
      stockOverlay,
      onQuantityValidate,
      saleRow,
      handleCode,
      handleKeyup,
    };
  },
});
